import catalogApi from "../../backend/services/catalog";
import store from "@/store/store";
import { cookieService } from "@/service/cookieConsentService";
import router from "@/router";

export default {
  async getProductsList({ commit, state }, type) {
    const currentRoute = router.currentRoute;

    // if view more is clicked, then only increase pagination by 1 otherwise not
    if (type === "viewMore") {
      state.pagination.paginationCurrentPage = state.pagination.paginationCurrentPage + 1;
    }
    let allFilters = { ...state.pagination, ...state.filters };
    const data = await catalogApi.getProducts(allFilters);
    commit("setProducts", data);
    if (data?.items && cookieService.isStatisticsGroupEnabled() && currentRoute.value.meta.trackingName !== 'cart_view') {
      commit("trackProduct", data?.items);
    }
    return data;
  },

  async getCategories({ commit, state }) {
    const data = await catalogApi.getCategoryTree(state.pagination);
    commit("setCategories", data);
    return data;
  },

  async getCategory({ commit }, urlKey) {
    const data = await catalogApi.getCategoryByUrlKey(urlKey);
    commit("setCategory", data);
    await store.dispatch("sliderImages/setImages", { data: data, type: "CATEGORY" });
    // if (cookieService.isStatisticsGroupEnabled()) {
    //   commit("trackCategory", data);
    // }
    return data;
  },

  async setFilters({ commit, state }, filters) {
    state.pagination.paginationCurrentPage = 1;
    commit("setFilters", filters);
  },

  async setCategoryName({ commit }, name) {
    commit("setCategoryName", name);
  },

  async setFooterCategoryClick({ commit }, status) {
    commit("setFooterCategoryClick", status);
  },

  async getProduct({ commit }, sku) {
    try {
      const data = await catalogApi.getProductBySku(sku);

      commit("setProduct", data);
      await store.dispatch("sliderImages/setImages", { data: data, type: "PRODUCT" });
      if (cookieService.isStatisticsGroupEnabled()) {
        commit("trackProduct", data);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        await router.push({name: "404"})
      } else {
        console.error("Error fetching product:", error);
      }
      throw error;
    }
  },

  async getDifferentDenominationProducts({ commit }, sku) {
    return await catalogApi.getDifferentDenominationProducts(sku);
  },
  async emptyProductsList({ commit }) {
    await commit("emptyProductsList");
  },
  async getLandingPage({ commit }, urlKey) {

    const data = await catalogApi.getLandingPage(urlKey);
    if (data.error) {
      router.push("/404");
    }
    //commit("setProducts", data);
    commit("setLandingPage", data);
    if (cookieService.isStatisticsGroupEnabled()) {
      commit("trackLandingPage", data);
    }
    await store.dispatch("sliderImages/setImages", { data: data, type: "LANDING_PAGE" });
    for (let index in data.landingPageContents) {
      let skus = data.landingPageContents[index].itemsSku.split(",");
      commit("emptyProductsList");
      const allProducts = [];

      for (let sku in skus) {
        if (skus[sku]) {
          allProducts.push(catalogApi.getProductBySku(skus[sku]))
        }
      }

      Promise.all(allProducts).then(allProductData => {
        allProductData.forEach(productData => {
          commit("setLandingPageProducts", productData);
        })
      })
    }
    return data;
  },
  async getBrands({ commit }, urlKey) {
    const data = await catalogApi.getBrands(urlKey);
    commit("setBrands", data);
    return data;
  },
  async getContacts({ commit }, urlKey) {
    const data = await catalogApi.getContacts(urlKey);
    data.sort((a, b) => a.name.localeCompare(b.name));
    const filteredByNames = data.filter((el) => {
      return el.name != "";
    });
    commit("setContacts", filteredByNames);
    return filteredByNames;
  },
};
